import React from "react"
import Styles from "../styles/components/HomeWorkSection.module.scss"
import Img from "./caseImage"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const WorkCard = props => (
  <AniLink
    to={props.slug}
    className={`${Styles["section-portfolioCard"]}`}
    target="div"
  >
    <Img
      filename={props.imageSrc}
      alt="test image"
      className={Styles["section-portfolioCard-img"]}
    />
    <div className={`${Styles["section-portfolioCard-text"]}`}>
      <p>{props.companyName}</p>
      <h2>{props.workTitle}</h2>
    </div>
  </AniLink>
)

export default WorkCard
